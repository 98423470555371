import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const TherapistsPageTemplate = () => {
  setTimeout(() => {
    const $ = window.$;

    $('#certification').on('change', function() {
      $('#certification-text').text('Certification added');
    });
    // Auto insert phone number hyphens
    $('#phone').keyup(window.autoInsertHyphens);

    // Gets the video src from the data-src on each button
    let $videoSrc;
    $('.video-btn').click(function () {
      $videoSrc = $(this).data("src");
    });


    // when the modal is opened autoplay it
    $('#myModal').on('shown.bs.modal', function (e) {

      // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
      $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
    })


    // stop playing the youtube video when I close the modal
    $('#myModal').on('hide.bs.modal', function (e) {
      // a poor man's stop video
      $("#video").attr('src', $videoSrc);
    })

    // document ready
  }, 1000);

  const scroll = (e) => {
    e.preventDefault();
    window.document.getElementById('therapist-form-main').scrollIntoView();
  };

  function resetForm($form) {
    $form.find('input:text, input:password, input:file, select, textarea').val('');
    $form.find('input:radio, input:checkbox')
      .removeAttr('checked').removeAttr('selected');
  }

  let submitTherapist = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const $ = window.$;
    const formId = 'therapistForm';
    const responseTextId = 'response-text';
    const url = 'https://hooks.zapier.com/hooks/catch/1846901/o5zqu0c/';
    const Http = new XMLHttpRequest();
    const form = window.document.getElementById(formId);
    function handleStateChange() {
      if(Http.readyState === XMLHttpRequest.DONE) {
        var status = Http.status;

        if (status >= 200 && status < 400) {
          window.document.getElementById(responseTextId).innerText = 'Form successfully submitted!';
          resetForm($(form));
          $("#gender").val($("#gender option:first").val());
        } else {
          window.document.getElementById('response-text').innerText =
            'There was an error submitting the form, please try again';
        }
      }
    }
    Http.onreadystatechange = handleStateChange;

    const formData = new window.FormData(form);
    Http.open('POST', url);
    Http.send(formData);
  };

  return(
    <main role="main" className="therapists-page">
      <Helmet>
        <title>Therapists</title>
      </Helmet>
      <div>
        {/* Begin Banner */}
        <div className="jumbotron therapist mb-0" style={{"backgroundImage": "linear-gradient(90deg, rgba(63, 62, 62, 0.47), rgba(63, 62, 62, 0), rgba(63, 62, 62, 0.47)), url(assets/newimg/fortherapistbanner.png)"}}>
          <div className="container text-light">
            <h1 className="display-5 text-white">Interested in<br/>
              working for Pete?</h1><a className="btn btn-gr the-bnr-btn pl-3 pr-3 fw-6"
                                       onClick={scroll}
                                       role="button">Apply now</a>
          </div>
        </div>
        {/* End Banner */}
        {/* Begin Second section */}
        <div className="container pt-5 pb-5 ph-second-wrap text-center">
          <h2>The work you love, with flexibility you'll love even more.</h2><br/>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="text-center">
                <div className="acc feature-img-bg mx-auto"><img className="circle-icon"
                                                                 src="/assets/icons/therapist/1.png" alt=""/></div>
                <h3>The Tech Advantage</h3>
                <p>We've invested in the kind of technology that gives therapist more control.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="acc feature-img-bg mx-auto big-size"><img className="circle-icon" src="/assets/icons/therapist/2.png" alt=""/></div>
                <h3>Help motivated patients</h3>
                <p>Treat ambulatory patients who understand what they need to do to improve.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="acc feature-img-bg mx-auto"><img className="circle-icon"
                                                                 src="/assets/icons/therapist/3.png" alt=""/></div>
                <h3>Get the right tools</h3>
                <p>We take care of the billing, back office and tech updates. You stay focused on your
                  patients.</p>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <div className="row the-ved text-center mt-md-5">
            <h2 className="display-block w-100">A PeteHealth Therapist's Perspective</h2>
            <p className="display-block w-100">See why more therapists are joining us.</p>
            <div className="img-wrap-the mt-4">
              <img id="videothumbnail" src="/assets/newimg/videothumbnail.png" alt=""/>
              <button type="button" className="btn video-btn" data-toggle="modal"
                      data-src="https://www.youtube.com/embed/l3c_-gpPt1I" data-target="#myModal"><img
                src="/assets/newimg/play.png" style={{width: '60px', height: 'auto'}}/></button>
            </div>
            <br/>
            <br/>
            <div className="display-block text-center w-100 the-apply-btn">
              <a className="btn btn-gr pl-3 pr-3 fw-6"
                 onClick={scroll} role="button">Apply now</a>
            </div>
            {/* Modal */}
            <div className="modal fade" id="myModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                    {/* 16:9 aspect ratio */}
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe className="embed-responsive-item" id="video" allowscriptaccess="always" allow="autoplay"
                              name="video"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Second section */}
        {/* Begin Third section */}
        <div className="jumbotron therapist-desc mb-0" style={{"backgroundImage": "url(assets/newimg/banner2.png)"}}>
          <div className="container">
            <div className="row">
              <div className="col-md-7"/>
              <div className="col-md-5 rounded card bg-white p-4">
                <h2 className="display-5 mb-3">We're all in this<br/>
                  together. Literally.</h2>
                <p>When you're a therapist with PeteHealth, you work at the nexus of better
                  technologies, better ways to deliver quality care and better patient outcomes.</p>
                <h3>Get paid well.</h3>
                <p>As a well educated therapist, you love what you do. We believe your pay should be
                  commensuate. You're a professional therapist, after all.</p>
                <h3>Innovation matters here.</h3>
                <p>PeteHealth understands the power technology has on what you do. Not only on how you
                  treat patients, but also in your dealings with the business side of things.<br/>
                  Because your time is valuable, we are constantly searching for more ways to get quality
                  access to your patients, track and record progress, and stay on top of scheduling,
                  billing and more.</p>
                <h3>Helping you be better</h3>
                <p>PeteHealth knows that the best physical therapists never stop learning. We give you
                  access to online resources and training to make sure you're informed of all the
                  latest findings through events, seminars and workshops.</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Third section */}
        {/* Begin Testimonials */}
        <div className="container-fluid text-center our-therapist">
          <div className="container">
            <h2 className="desk-form-sub">What our therapists say about us?</h2>
            <h2 className="mob-form-sub">Testimonials</h2>
            <p>They give it to us strainght. And we'll give it to you straight It's the only
              way we can all get better.</p>
            <div id="therapistcarousel" className="the-carousel-card carousel slide" data-ride="carousel">
              {/* Indicators */}
              <ul className="carousel-indicators"/>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row mb-md-4">
                    <div className="col-md-12">
                      <div className="card ca-shadow-sm border-0 p-4 the-card">
                        <p>
                          "Flexibility means everything to me. I can't be tied down to a rigid
                          schedule. I'm a Pete because I can arrange my work life around my personal
                          life. It really is a win-win. Every second I can spend with patients is
                          more valuable for all of us. It all adds up to better outcomes."
                        </p>
                        <h3>Jane L.</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row mb-md-4">
                    <div className="col-md-12">
                      <div className="card ca-shadow-sm border-0 p-4 the-card">
                        <p>
                          "My patients they know an expect me to show up at their treatments
                          consistently and there's really something to be said about actually
                          going to them. For me, it's a nice to change my scenery and it means
                          never being confined to some poorly-lit clinic."
                        </p>
                        <h3>Kevin M</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row mb-md-4">
                    <div className="col-md-12">
                      <div className="card ca-shadow-sm border-0 p-4 the-card">
                        <p>
                          "The freedom I have as a Pete is amazing. I feel so much more connected
                          to my patients than ever before. I'm even free to go with my patients as
                          they follow up with their doctors. When you join Pete Health as a licensed
                          physical therapist, you really get so much more. More flexibility.
                          More connectivity. More Continuity."
                        </p>
                        <h3>Danica P.</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a className="carousel-control-prev ca-shadow-sm rounded-circle white-bg" href="#therapistcarousel"
                 role="button" data-slide="prev">←</a> <a
              className="carousel-control-next ca-shadow-sm rounded-circle white-bg" href="#therapistcarousel"
              role="button" data-slide="next">→</a>
            </div>
          </div>
        </div>
        {/* End Testimonials */}
        {/* Begining Application form Section */}
        <div id="therapist-form-main" className="bg-gray pt-5 pb-5 text-center the-form-wrap">
          <div className="pb-4 mt-5">
            <h2>Ready to join?</h2>
            <p>Submit the form below and be on your way to becoming a PeteHealth therapist.</p>
          </div>
          <div id="response-text" className="text-center text-danger pb-2"/>
          <div className="container therapist-form ca-shadow-sm rounded p-4 bg-white mx-auto mb-5">
            <form id="therapistForm" name="therapistForm" onSubmit={submitTherapist}>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <input className="form-control fa-input" id="fname" name="fname" placeholder="First name*" type="text"
                         required/>
                </div>
                {/* form-group */}
                <div className="form-group col-md-6 col-sm-12">
                  <input className="form-control fa-input" id="lname" name="lname" placeholder="Last name*" type="text"
                         required/>
                </div>
                {/* form-group */}
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <input aria-describedby="emailHelp" className="form-control fa-input" id="email" name="email"
                         placeholder="Email*" type="email" required/>
                </div>
                {/* form-group */}
                <div className="form-group col-md-6 col-sm-12">
                  <input className="form-control fa-input" id="phone" name="phone" placeholder="Phone number*" type="text"
                         maxLength={12} required/>
                </div>
                {/* form-group */}
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <select className="form-control fa-input" id="gender" name="gender" type="text" required style={{paddingLeft: '8px'}} required>
                    <option value="ml" selected disabled>
                      Male/Female
                    </option>
                    <option value="Male">
                      Male
                    </option>
                    <option value="Female">
                      Female
                    </option>
                  </select>
                  {/* <input class="form-control fa-input" id="gender" name="gender" placeholder="Gender" type="text" required> */}
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <input className="form-control fa-input" id="zip" name="zip" placeholder="Zipcode*" maxLength={5}
                         type="text" required/>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <input className="form-control fa-input" id="attribution" name="attribution"
                         placeholder="How did you hear about PeteHealth?" type="text" required/>
                </div>
                <div className="form-group col-md-6 col-sm-12 upload-btn-wrapper text-left" style={{overflow: 'hidden'}}>
                  <button id="certification-text" className="btn upload" style={{padding: '.375rem .75rem'}}>Upload
                    Resume
                  </button>
                  <input type="file" id="certification" name="certification"/>
                </div>
              </div>
              {/* form-group */}
              <button className="btn ca-btn btn-gr p-3 w-100"  type={"submit"}>Submit</button>
            </form>
          </div>
        </div>
        {/* End Application form Section */}
      </div>
    </main>
  );
}

const TherapistsPage = () => {
  return (
    <Layout>
      <TherapistsPageTemplate />
    </Layout>
  )
};


export default TherapistsPage;
